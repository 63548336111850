import React, { useState, useEffect, useRef } from 'react';
import fonts from './fonts.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './quote.random.css';
import axios from 'axios';
import { IQuote } from './model/Quote';
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import MainOptions from '../options/main.options';
import { useNavigate } from 'react-router-dom';

const RandomQuote = () => {

  const getRandomFont = () => {
    const randomIndex = Math.floor(Math.random() * fonts.fonts.length);
    return fonts.fonts[randomIndex];
  };

  const baseFontSize = 42; // Base font size for 0 - 200 chars
  const showOptionsBtn = true;
  const navigate = useNavigate();

  const [selectedFont, setSelectedFont] = useState(getRandomFont());
  const [fontSize, setFontSize] = useState(baseFontSize); // Initial font size
  const [text, setText] = useState('');
  const [quote, setQuote] = useState<IQuote>({} as any); 
  const [showRefresh, setShowRefresh] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [fullyDisplayed, setFullyDisplayed] = useState<boolean>(false);
  const [nextClicked, setNextClicked] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedFont(getRandomFont());
    }, 60000); // Change the font every 25 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchNewQuote();
  }, []);


  const updateViewCount = (quote: IQuote) => {
    // Define the URL for updating the quote based on the ID
    const apiUrl = `https://chinup-dev-344101f2de4c.herokuapp.com/api/quotes/${quote._id}`;
    // Use axios to send a PUT request with the updated quote data
    quote.viewCount = (quote.viewCount ?? 0) + 1;
    axios.patch(apiUrl, quote)
      .then(() => {
        // Do nothing once the view count is updated
      })
      .catch((error) => {
        console.error('Error updating quote:', error);
      });
  };
  
  const fetchNewQuote = () => {
    setNextClicked(true);
    // Make an API call to fetch the quote data
    fetch('https://chinup-dev-344101f2de4c.herokuapp.com/api/quotes/random')
    .then((response) => response.json())
    .then((data) => {
      // Set the title from the fetched data
      const fetchedQuote = data.quote;
      setQuote(fetchedQuote);
      const quoteNAuthor = `${fetchedQuote.title}`;
      typingInterval(quoteNAuthor);
      updateViewCount(fetchedQuote);
      setNextClicked(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setNextClicked(false);
    });
  }

  const toggleShowOptions = () => {
    setShowOptions((prevShowOptions) => !prevShowOptions);
    setFontSize(!showOptions? baseFontSize * 0.7: baseFontSize);
  }

  const calculateFontSize = (text:string) => {
    const maxLength = 16; // Maximum number of characters for 56px font size
  
    if (text.length <= maxLength) {
      // If text length is within the limit, use the base font size
      return baseFontSize;
    } else {
      // If text length exceeds the limit, calculate the relative font size
      const extraChars = text.length - maxLength;
      const reductionFactor = Math.ceil(extraChars / 10); // Adjust by reducing 1px for every 10 extra characters
      const adjustedFontSize = baseFontSize - reductionFactor;
  
      // Ensure the adjusted font size doesn't go below a minimum value (e.g., 12px)
      const minimumFontSize = 12;
      return Math.max(minimumFontSize, adjustedFontSize);
    }
  }
  
  const navToCreate = () => {
    navigate("/quote-crud/anon");
  }

  const typingInterval = (fullText: string) =>{

    setTimeout(()=> setShowRefresh(false), 100);

    let currentIndex = 0;
    const intervalSpeed = 150; 

    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        const subText = fullText.substring(0, currentIndex)
        setText(subText);
        setFontSize(calculateFontSize(subText));
        setFullyDisplayed(false);
        currentIndex++;
      } else {
        clearInterval(typingInterval);
        setText(fullText); // Set the final text with the correct font family
        setTimeout(()=> setShowRefresh(true), 400);
        setFullyDisplayed(true);
      }
    }, intervalSpeed); // Adjust the interval for typing speed

    return () => clearInterval(typingInterval);
  }

  return (
    <div className="random-quote-container">
      <div
        style={{
          fontFamily: `${selectedFont.family}, ${selectedFont.fallback}`,
          fontSize: `${fontSize}px`, // Set font size dynamically
          fontWeight: 'bold',
          overflow: 'hidden',
        }}
      >
        <p>{text}</p>
        {
          fullyDisplayed 
          &&
          <p className='author'>
            - {quote.author || 'Unknown'}
          </p>
        }
      </div>
      {/* Refresh button with Font Awesome icon */}
      {showRefresh && 
        <div>
          {
            showOptionsBtn &&
            <button className="btn options-button" onClick={toggleShowOptions}>
              <span>
                  OPTIONS
                  <FontAwesomeIcon icon={faSliders} />             
              </span>
            </button>
          }
          {
            showOptions && 
            <MainOptions 
              onAdd={navToCreate}
            />
          }
          <button className="btn refresh-button" onClick={fetchNewQuote}>
            <span>
              NEXT
              <i className="fas fa-sync-alt" style={{ marginLeft: '8px' }}></i>
            </span>
          </button>
        </div>
      }
    </div>
  );
};

export default RandomQuote;
