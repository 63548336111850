import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './quote-crud.scss';
import { useParams } from 'react-router-dom';
import axios from 'axios';

interface QuoteCrudProps {}

const QuoteCrud: FC<QuoteCrudProps> = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [isAnon, setIsAnon] = useState<boolean>(false);
  const [pageTitle, setPageTitle] = useState<string>('Create');
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  // Define state variables to manage form input values
  const [quote, setQuote] = useState({
    title: '',
    body: '',
    poster: '',
    author: '',
    tags: [''],
    isPrivate: false,
  });

  useEffect(() => {
    setIsAnon(id === 'anon');

    if(id !== 'new' && id != 'anon'){
      setPageTitle('Update');
      // Define the URL for fetching the quote based on the ID
      const apiUrl = `https://chinup-dev-344101f2de4c.herokuapp.com/api/quotes/${id}`;

      // Use axios to fetch the quote data
      axios.get(apiUrl)
        .then((response) => {
          // Set the fetched quote data in the state
          setQuote(response.data.quote ?? {});

        })
        .catch((error) => {
          console.error('Error fetching quote:', error);
        });
    }
  }, [id]);

  useEffect(() => {
    checkFormValidity();
  }, [quote]);

  const handleUpdate = () => {
    // Define the URL for updating the quote based on the ID
    const apiUrl = `https://chinup-dev-344101f2de4c.herokuapp.com/api/quotes/${id}`;

    // Use axios to send a PUT request with the updated quote data
    axios.patch(apiUrl, quote)
      .then(() => {
        // Redirect to the quote detail page after successful update
        navigate(`/quote-view/${id}`);
      })
      .catch((error) => {
        console.error('Error updating quote:', error);
      });
  };

  const handleCreate = () => {
    // Define the URL for updating the quote based on the ID
    const apiUrl = `https://chinup-dev-344101f2de4c.herokuapp.com/api/quotes`;

    // Use axios to send a PUT request with the updated quote data
    axios.post(apiUrl, quote)
      .then((response) => {
        // Redirect to the quote detail page after successful update
        setQuote(response.data);
        navigate(`/quote-view/${response.data._id}`);
      })
      .catch((error) => {
        console.error('Error updating quote:', error);
      });
  };

  // Handle form input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
  
    if (type === 'checkbox') {
      setQuote((prevData) => ({
        ...prevData,
        [name]: (e.target as HTMLInputElement).checked,
      }));
    } else if (type === 'radio') {
      // Handle radio inputs with a boolean value
      setQuote((prevData) => ({
        ...prevData,
        [name]: value === 'true', // Convert to boolean
      }));
    }  
    else if (name === 'tags') {
        // Split the input value into an array of tags
        const tagsArray = value.split(',');
        setQuote((prevData) => ({
          ...prevData,
          [name]: tagsArray,
        })); 
    } else {
      setQuote((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }


  }; 
  
  const checkFormValidity = () => {
    // Define an array of required field names
    const requiredFields = ['title', 'body', 'poster', 'author'];
  
    // Filter the formFields array to include only required fields
    const requiredFormFields = Object.keys(quote)
      .filter((fieldName) => requiredFields.includes(fieldName))
      .map((fieldName) => (quote as Record<string, any>)[fieldName]);
  
    // Check if all required fields are valid
    const isValid = requiredFormFields.every((field) => {
      if (typeof field === 'string') {
        return field.trim() !== ''; // Check if the string is not empty after trimming whitespace
      } else if (typeof field === 'boolean') {
        return true; // Booleans are always considered valid
      } else if (Array.isArray(field)) {
        return field.length > 0; // Check if the array has elements
      }
      return false; // Handle other types as needed
    });
  
    setIsFormValid(isValid);
  };  
  
  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if(id && id !== 'new' && id !== 'anon'){
      handleUpdate();
    }
    else{
      handleCreate();
    }
  };

  const handleCancel = (e: React.FormEvent) => {
    e.preventDefault();
    navigate(-1);
  }

  return (
    <div className="quote-crud">
      <h4>{pageTitle} your Quote</h4>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Your quote
          </label>
          <textarea
            className="form-control"
            id="title"
            name="title"
            value={quote.title}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="body" className="form-label">
            What it means to you
          </label>
          <textarea
            className="form-control"
            id="body"
            name="body"
            value={quote.body}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="poster" className="form-label">
            Poster (your name)
          </label>
          <input
            type="text"
            className="form-control"
            id="poster"
            name="poster"
            value={quote.poster}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="author" className="form-label">
            Author
          </label>
          <input
            type="text"
            className="form-control"
            id="author"
            name="author"
            value={quote.author}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="tags" className="form-label">
            Tags
          </label>
          <input
            type="text"
            className="form-control"
            id="tags"
            name="tags"
            value={quote.tags.join(',')}
            onChange={handleInputChange}
            placeholder='e.g "wisdom,empowering"'
          />
        </div>

        <div className="mb-3">
          <div className="row">
            <div className="col">
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input visually-hidden"
                  id="private-yes"
                  name="isPrivate"
                  value="true"
                  checked={quote.isPrivate}
                  onChange={handleInputChange}
                />
                <label
                  className={`form-check-label btn btn-outline-primary ${
                    quote.isPrivate ? 'active' : ''
                  }`}
                  htmlFor="private-yes"
                >
                  {quote.isPrivate ? 'Keep this private' : 'Keep this private'}
                </label>
              </div>
            </div>
            <div className="col">
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input visually-hidden"
                  id="private-no"
                  name="isPrivate"
                  value="false"
                  checked={!quote.isPrivate}
                  onChange={handleInputChange}
                />
                <label
                  className={`form-check-label btn btn-outline-primary ${
                    !quote.isPrivate ? 'active' : ''
                  }`}
                  htmlFor="private-no"
                >
                  {!quote.isPrivate ? 'Share this publicly' : 'Share this publicly'}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className='form-buttons'>
          <button type="button" className="mr-2 btn btn-secondary" onClick={handleCancel}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary" onClick={handleSubmit} disabled={!isFormValid}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default QuoteCrud;
