import React, { FC, useState, useEffect, useRef } from 'react';
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './main.options.scss';

interface MainOptionsProps {
  onAdd: () => void
}

const MainOptions: FC<MainOptionsProps> = (props: MainOptionsProps) => {
  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div className="main-options">
        <div className="options-container">
          <button className="d-none btn btn-outline-primary">
            <i className="fa-regular fa-heart"></i>
            Save this quote
          </button>
          <button className="d-none btn btn-outline-primary">
            <i className="fa-solid fa-share"></i>
            Share this quote
          </button>
          <button className="d-none btn btn-outline-primary">
            <i className="fa-solid fa-right-to-bracket"></i>
            Login/Register
          </button>
          <button className="btn btn-outline-primary" onClick={props.onAdd}>
            <i className="fa-solid fa-plus"></i>
            Contribute a quote
          </button>
        </div>
    </div>
  );

}

export default MainOptions;
