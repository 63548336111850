import React, { FC, useEffect, useState } from 'react';
import './quote-view.scss';
import {IQuote} from '../quote/model/Quote';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

interface QuoteViewProps {}

interface QuoteViewProps {
  data: IQuote
}

const QuoteView: FC<QuoteViewProps> = ({ data }) => {

  const navigate = useNavigate();
  const [quote, setQuote] = useState<IQuote>(data);
  const { id } = useParams();
  const toMain = () => {
    navigate("/");
  }

  useEffect(() => {

    if(id){
      // Define the URL for fetching the quote based on the ID
      const apiUrl = `https://chinup-dev-344101f2de4c.herokuapp.com/api/quotes/${id}`;

      // Use axios to fetch the quote data
      axios.get(apiUrl)
        .then((response) => {
          // Set the fetched quote data in the state
          setQuote(response.data.quote ?? {});

        })
        .catch((error) => {
          console.error('Error fetching quote:', error);
        });
    }
  }, [id]);

  return (
    <div className="quote-view">
      <h4>Your Quote</h4>
      <div className="mb-3">
        <label className="form-label">Your quote</label>
        <div className="form-text text-left">{quote.title}</div>
      </div>

      <div className="mb-3">
        <label className="form-label">What it means to you</label>
        <div className="form-text text-left">{quote.body}</div>
      </div>

      <div className="mb-3">
        <label className="form-label">Poster (your name)</label>
        <div className="form-text text-left">{quote.poster}</div>
      </div>

      <div className="mb-3">
        <label className="form-label">Author</label>
        <div className="form-text text-left">{quote.author}</div>
      </div>

      <div className="mb-3">
        <label className="form-label">Tags</label>
        <div className="form-text text-left">{quote.tags}</div>
      </div>

      <div className="mb-3">
        <label className="form-label">Privacy</label>
        <div className={`form-text ${quote.isPrivate ? 'private' : 'public'}`}>
          {quote.isPrivate ? 'Keep this private' : 'Share this publicly'}
        </div>
      </div>

      <div className='form-buttons'>
          <button type="button" className="mr-2 btn btn-secondary" onClick={toMain}>
            Back to Main Page
          </button>
      </div>

    </div>
  );
};

export default QuoteView;

