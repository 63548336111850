import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faCancel, faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IQuote } from '../quote/model/Quote'
import './quote-list.scss';
import axios from 'axios';

interface QuoteListProps {
}

interface QuoteListProps1{
  quotes: IQuote[];
  maxTitleLength?: number;
  onDelete: (quoteId: string) => void;
  onEdit: (quoteId: string) => void;
}

const QuoteList: FC<QuoteListProps> = () => {
  
  const [quotes, setQuotes] = useState<IQuote[]>([] as IQuote[]);
  let navigate = useNavigate();
  const maxTitleLength = 150;
  const limit = 100;
  const onDelete = (quoteId: string) => {};
  const onEdit = (quoteId: string) => {
    navigate(`/quote-crud/${quoteId}`);
  };

  const onApprove = (quoteId: string) =>{ return onUpdateApproval(quoteId, true);}
  const onDisapprove = (quoteId: string) =>{ return onUpdateApproval(quoteId, false);}
  
  const onUpdateApproval = (quoteId: string, approve: boolean) => {
    // Define the URL for updating the quote based on the ID
    const apiUrl = `https://chinup-dev-344101f2de4c.herokuapp.com/api/quotes/${quoteId}`;
    const quote = quotes.find(q => q._id === quoteId);
    if(quote){
      quote.isApproved = approve;
      // Use axios to send a PUT request with the updated quote data
      axios.patch(apiUrl, quote)
        .then((response) => {
          // Redirect to the quote detail page after successful update
          const updatedQuotes = quotes.map(q => (q._id === quoteId ? response.data : q));
          setQuotes(updatedQuotes);
        })
        .catch((error) => {
          console.error('Error updating quote:', error);
        });
    }
  }

  useEffect(() => {
    // Define an async function to fetch the data
    const fetchData = async () => {
      try {
        // Fetch data from the API endpoint
        const response = await fetch('https://chinup-dev-344101f2de4c.herokuapp.com/api/quotes');
        
        // Check if the response is successful (status code 200)
        if (response.ok) {
          // Parse the JSON response
          const data = await response.json();
          
          // Extract the first 'limit' quotes from the data
          const limitedQuotes = data.quotes.slice(0, limit);
          
          // Set the quotes in the state
          setQuotes(limitedQuotes);
        } else {
          console.error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // The empty dependency array ensures that this effect runs once on component mount

    return (
      <div className="quote-list">
        {quotes.map((quote) => (
          <div className="quote-card" key={quote._id}>
            <div className="quote-title">
              {quote.title.length > maxTitleLength
                ? `${quote.title.slice(0, maxTitleLength)}...`
                : quote.title}
            </div>
            <div className="quote-details">        
              <div className="quote-actions">
                {
                  quote.isApproved &&
                  <button
                    className="btn btn-outline-danger"
                    onClick={() => onDisapprove(quote._id)}
                  >
                  Disapprove  
                  <span className="icon-space">
                      <FontAwesomeIcon icon={faCancel} />
                    </span>
                </button>
                }
                {
                  !quote.isApproved &&
                  <button
                    className="btn btn-outline-primary custom-button"
                    onClick={() => onApprove(quote._id)}
                  >
                    Approve
                    <span className="icon-space">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                  </button>
                }
                <button
                  className="quote-action-button"
                  onClick={() => onEdit(quote._id)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button
                  className="quote-action-button"
                  onClick={() => onDelete(quote._id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
            <div className="quote-details">
              <div className="quote-author">{quote.author}</div>
              <span className="icon-space">
                <FontAwesomeIcon icon={faEye} /> {quote.viewCount}
              </span>
            </div>
          </div>
        ))}
    </div>
)
};

export default QuoteList;
