import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import RandomQuote from './components/quote/quote.random';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import QuoteCrud from './components/quote-crud/quote-crud';
import QuoteList from './components/quote-list/quote-list';
import QuoteView from './components/quote-view/quote-view';
import { IQuote } from './components/quote/model/Quote';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

//dotenv.config();

function App() {

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_ApiKey,
    authDomain: process.env.REACT_APP_AuthDomain,
    projectId: process.env.REACT_APP_ProjectId,
    storageBucket: process.env.REACT_APP_StorageBucket,
    messagingSenderId: process.env.REACT_APP_MessagingSenderId,
    appId: process.env.REACT_APP_AppId,
    measurementId: process.env.REACT_APP_MeasurementId
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <Routes>
            <Route path="/" element={<RandomQuote />} />
            <Route path="/quote-crud/:id" element={<QuoteCrud />} />
            <Route path="/quote-view/:id" element={<QuoteView data={{} as IQuote} />} />
            <Route path="/quote-list" element={<QuoteList />} />
          </Routes>
        </Router>
      </header>
    </div>
  );
}

export {};
export default App;
